.hero-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 5rem;
}

.hero-container::after,
.hero-container::before {
  content: "";
  width: 28.125rem;
  height: 28.125rem;
  border-radius: 28.125rem;
  background: #7c66e3;
  position: absolute;
  z-index: -1;
  filter: blur(225px);
}

.hero-container::after {
  top: -3rem;
  left: -5rem;
}

.hero-container::before {
  bottom: 2rem;
  right: 0rem;
}

.hero-content {
  flex: 1;
}

.hero-content h2 {
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 5rem;
  margin-bottom: 1rem;
}

.hero-content h5 {
  font-size: 1.6rem;
  font-weight: 600;
}
.hero-content h5 span {
  color: #7bc0e8;
}

.hero-content p {
  margin-top: 2rem;
  width: 80%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
}

/* .hero-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 5rem;
}

.hero-img > div {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.hero-img > div > img {
  width: 25rem;
  height: 30rem;
  object-fit: cover;
  border-radius: 50px;

  transition: all 0.3s ease;
} */

.hero-img img {
  width: 20rem;
  border-radius: 50rem;
  /* margin-top: 5rem; */
}

.tech-icon {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.65rem;
  border: 1px solid #6852ba;
}

.tech-icon img {
  width: 3rem;
  height: auto;
  transition: all 0.3s ease;
}

.hero-img > div > img:hover,
.tech-icon img:hover {
  transform: translateY(-0.5rem);
}

.hero-btn {
  margin-top: 1.4rem;
  display: flex;
  gap: 2rem;
}

.hero-btn button {
  border: 1px solid #fff;
  border-radius: 0.6rem;
  outline: none;
}

.btn a {
  display: block;
  color: #fff;
  padding: 0.6rem 2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: var(--cursorPointer);
}
.btn-contact {
  background-color: #3c93a2;
}
.btn-cv {
  background-color: #1abf51;
}

.btn-cv a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

@media (max-width: 1025px) {
  .hero-content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .hero-content p {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }

  .hero-img > div > img {
    width: 20rem;
  }
  .hero-img img {
    width: 16rem;
    border-radius: 50rem;
    /* margin-top: 5rem; */
  }

  .tech-icon {
    width: 4rem;
    height: 4rem;
  }

  .tech-icon img {
    width: 2.5rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-img {
    margin: 2rem 0 2rem 0;
  }

  .hero-container h2 {
    margin-top: 3rem;
  }
  .hero-container h5 {
    font-size: 1.4rem;
  }
  .hero-container p {
    font-size: 1rem;
  }

  .hero-content h2,
  .hero-content h5,
  .hero-content p {
    width: auto;
    text-align: center;
  }

  .hero-container::after,
  .hero-container::before {
    content: "";
    width: 18rem;
    height: 18rem;
  }

  .hero-container::after {
    top: 0rem;
    left: 0rem;
  }

  .hero-container::before {
    bottom: 2rem;
    right: 0rem;
  }

  .hero-btn {
    justify-content: center;
  }
}

.btn a {
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
}

@media (max-width: 600px) {
  .hero-content h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .hero-content p {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }

  .hero-img {
    gap: 1rem;
    position: relative;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 16rem;
  }

  .tech-icon {
    width: 3.3rem;
    height: 3.3rem;
  }

  .tech-icon img {
    width: 2rem;
  }
}

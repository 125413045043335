.skills-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 2.5rem;
  text-align: center;
  background: rgba(22, 17, 47, 0.398);
  backdrop-filter: blur(1rem);
  cursor: var(--cursorPointer);
  transition: all 0.3 ease;
}

.skills-card:hover,
.skills-card.active {
  background: linear-gradient(180deg, #aa94fe 0%, #7c5fe6 100%);
}

.skills-card span {
  font-size: 1rem;
  font-weight: 500;
}

.skill-icon img {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

@media (max-width: 1025px) {
  .skills-card {
    padding: 2rem;
    gap: 0.4rem;
  }

  .skills-card span {
    font-size: 1rem;
  }

  .skill-icon {
    width: 3rem;
    height: 3rem;
  }

  .skill-icon img {
    width: 2.5rem;
  }
}

@media (max-width: 768px) {
  .skills-card span {
    font-size: 0.9rem;
  }

  .skill-icon img {
    width: 3rem;
  }
}
